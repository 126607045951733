import { useGetGym } from '#api/gyms';
import { useGetLecture } from '#api/lectures';
import alt_image from '#assets/svg/alt_image.svg';
import { BottomPolicy, BoxIcon, Button, Text } from '#atoms';
import { PATH } from '#const/path';
import usePayment from '#hooks/usePayment';
import { LocationModal } from '#molecules';
import { useApplications } from '#providers/Applications';
import { useUserInfo } from '#providers/UserInfo';
import { $applicationData } from '#states/atoms/applicationState';
import { $confirmationFormState } from '#states/atoms/confirmationFormState';
import { calculatePriceBySession } from '#utils/calculatePriceBySession';
import sessionStorageManager from '#utils/sessionStorageManager';
import { getPureText } from '#utils/typeguard';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { URLSEARCH_PARAMS } from 'src/shared/constant';
import styled from 'styled-components';
import { ChildrenContainer } from '../DashboardChartSelect/styles';
import { TitleContainer } from '../MainIntroduction/styles';
import { HeaderContainer } from '../ProfileSchedule/styles';
import {
  AltImageBox,
  ButtonContainer,
  CoachInformationContainer,
  CoachInformationItem,
  Container,
  FragmentContainer,
  GymLocationButton,
  ItemContainer,
  PaymentItem,
  TextContainer,
  UserInformationConatiner,
  UserInformationItem
} from './styles';

interface LocationState {
  gymId: string;
  lectureId: string;
}

export function ApplicationPayment() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { gymId, lectureId } = useRecoilValue($applicationData);
  const { userInfo } = useUserInfo();
  // @TODO: 꼭 깔끔하게 고칠것
  // if (!state) {
  //   return null;
  // }
  // const { gymId = null, lectureId = null } = state.data as LocationState;
  if (!gymId || !lectureId) {
    return null;
  }
  const { data: lectureData } = useGetLecture(lectureId);
  const { data: gymData } = useGetGym(gymId);
  const { requestPayment } = useApplications();
  const [{ quantity, gymName }] = useRecoilState($confirmationFormState);

  // 신청서 작성에 필요한 confirmatioFormId
  const { confirmationFormId } = sessionStorageManager();

  const [locationModalOpen, setLocationModalOpen] = useState<boolean>(false);

  const { currentApplication } = useApplications();
  const { initializePayment, paymentComplete, isConfirmationFormRequired } =
    usePayment({
      data: {
        coachId: lectureData?.coachId ?? '',
        lectureId,
        lectureName: getPureText(lectureData?.title ?? ''),
        userEmail: userInfo?.email ?? '',
        userName: userInfo?.name ?? '',
        userPhone: userInfo?.phone ?? '',
        amount:
          lectureData && quantity
            ? calculatePriceBySession(
                lectureData,
                quantity,
                lectureData?.price ?? 0
              )
            : 0,
        count: quantity ?? 0,
        gymId: gymId
      }
    });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (paymentComplete) {
      queryClient.invalidateQueries(['LECTURESINGLIST']);

      // 수업 신청서 작성이 필수 -> 수업신청서 작성 페이지
      // 수업 신청서 작성이 필수 X -> 수업 예약하기 페이지
      navigate(
        isConfirmationFormRequired
          ? `${PATH.APPLICATION}?${URLSEARCH_PARAMS.CONFIRMATION_FORM_ID}=${confirmationFormId}`
          : PATH.SUCCESS
      );
    }
  }, [paymentComplete]);

  const payLectureAndProgress = async () => {
    requestPayment({ lectureId, gymId, count: quantity ?? 0 }, result => {
      result.merchant_uid && initializePayment(result.merchant_uid);
    });
  };

  return (
    <PrevWithTitleTextTemplate title={'결제 하기'}>
      <StyledContainer>
        <FragmentContainer style={{ paddingTop: 0 }} className="class-info">
          <TextContainer>
            <Text textStyle="t3">신청 수업 정보</Text>
          </TextContainer>
          <CoachInformationContainer>
            <CoachInformationItem style={{ display: 'flex' }}>
              {lectureData?.coachProfile[0]?.resizedLink ? (
                <img src={lectureData.coachProfile[0].resizedLink} />
              ) : (
                <AltImageBox>
                  <img src={alt_image} width={26} height={26} />
                </AltImageBox>
              )}
              <ItemContainer>
                <Text>{lectureData?.coachName} 전문가</Text>
                <Text
                  textStyle="c1"
                  textColor="gray2"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {lectureData?.title}
                </Text>
              </ItemContainer>
            </CoachInformationItem>
            <CoachInformationItem>
              <ItemContainer className="gym">
                <Text>{gymData?.name}</Text>
                <GymLocationButton onClick={() => setLocationModalOpen(true)}>
                  위치 보기
                </GymLocationButton>
              </ItemContainer>
            </CoachInformationItem>
            {currentApplication?.schedules &&
              currentApplication?.schedules.length > 0 && (
                <CoachInformationItem style={{ flexDirection: 'column' }}>
                  {currentApplication.schedules.map((date, idx) => {
                    const selectedDate = dayjs(date);
                    const formattedDate = `${
                      selectedDate.month() + 1
                    }월 ${selectedDate.date()}일 ${selectedDate
                      .locale('ko')
                      .format('dddd')} ${selectedDate.format(
                      'HH'
                    )}:00 - ${selectedDate.add(1, 'hour').format('HH')}:00`;
                    return <Text key={idx}>{formattedDate}</Text>;
                  })}
                </CoachInformationItem>
              )}
          </CoachInformationContainer>
        </FragmentContainer>
        <hr />
        <FragmentContainer>
          <TextContainer>
            <Text textStyle="t3">고객 정보 확인</Text>
          </TextContainer>
          <UserInformationConatiner>
            <UserInformationItem>
              <Text textStyle="t3">이름</Text>
              <Text>{userInfo?.name}</Text>
            </UserInformationItem>
            <UserInformationItem>
              <Text textStyle="t3">핸드폰 번호</Text>
              <Text>{userInfo?.phone}</Text>
            </UserInformationItem>
          </UserInformationConatiner>
        </FragmentContainer>
        <hr />
        <FragmentContainer>
          <TextContainer>
            <Text textStyle="t3">결제 금액</Text>
          </TextContainer>
          <PaymentItem>
            <div>
              <Text textStyle="c1" textColor="gray2">
                수업 정보
              </Text>
              <Text style={{ whiteSpace: 'pre-wrap' }}>
                {lectureData?.title}
              </Text>
            </div>
            <div>
              <Text textStyle="c1" textColor="gray2">
                회차정보
              </Text>
              <Text>{quantity}회</Text>
            </div>
          </PaymentItem>
          <PaymentItem>
            <div>
              <Text textStyle="c1">총 결제 금액</Text>
              <Text>{(state.data.price ?? 0).toLocaleString('ko-KR')}원</Text>
            </div>
          </PaymentItem>
        </FragmentContainer>
        <BottomPolicy />
        <ButtonContainer>
          <Button onClick={payLectureAndProgress}>결제하기</Button>
        </ButtonContainer>
        {gymData && locationModalOpen && (
          <LocationModal
            data={gymData}
            onCancel={() => {
              setLocationModalOpen(false);
            }}
          />
        )}
      </StyledContainer>
    </PrevWithTitleTextTemplate>
  );
}

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;

  & > hr {
    border: 4px solid var(--color-border);
  }
`;

interface Props {
  title: string | React.ReactNode;
  caption?: string;
  prevRoute?: string;
  onPrevClick?: () => void;
}

// 기존에 사용되던 컴포넌트가 너무 많이 참조되어 있어서 변경이 어려움
// 대신 새로운 컴포넌트를 만들어서 사용하도록 함
function PrevWithTitleTextTemplate({
  prevRoute,
  onPrevClick,
  title,
  caption,
  children
}: PropsWithChildren<Props>) {
  const navigate = useNavigate();

  const onClickArrow = useCallback(() => {
    onPrevClick && onPrevClick();
    if (prevRoute) navigate(prevRoute);
    else navigate(-1);
  }, [prevRoute, onPrevClick]);

  return (
    <Container
      style={{ position: 'relative', height: 'calc(var(--vh) * 100)' }}
    >
      <HeaderContainer
        style={{
          display: 'flex',
          gap: '12px',
          paddingTop: '20px',
          paddingBottom: '20px',
          position: 'sticky',
          top: '0',
          backgroundColor: 'var(--color-background)',
          zIndex: '2'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BoxIcon
            type="prev-arrow"
            boxColor="transparent"
            size={18}
            onlyIcon={true}
            onClick={onClickArrow}
          />
        </div>
        <TitleContainer style={{ padding: 0 }}>
          {typeof title === 'string' ? (
            <Text textStyle="display">{title}</Text>
          ) : (
            title
          )}
          {caption && <Text textColor="gray2">{caption}</Text>}
        </TitleContainer>
      </HeaderContainer>

      <ChildrenContainer style={{ paddingTop: 0, height: 'auto' }}>
        {children}
      </ChildrenContainer>
    </Container>
  );
}
