import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { TabContainer } from 'src/components/molecules/TabListViewer/styles';

export const MainConsumerView = () => {
  const { pathname } = useLocation();

  const mainViewUrlList = ['/introduce', '/', '/expert', '/chart'];

  const [currentTabIndex, setCurrentTabIndex] = useState(-1);

  useEffect(() => {
    const tabIndex = mainViewUrlList.findIndex(url => {
      // 프리렌더링된 화면이 있을경우 pathname + '/'로 리다이렉팅됨
      const notRedirectPathname = pathname.endsWith('/')
        ? pathname.slice(0, -1)
        : pathname;

      if (pathname === '/') {
        return url === '/';
      }

      return url === notRedirectPathname;
    });

    setCurrentTabIndex(tabIndex);
  });

  const tabs = [
    { title: '소개', url: '/introduce' },
    { title: '홈', url: '/' },
    { title: '트레이너', url: '/expert?tab=전체' },
    { title: '운동차트', url: '/chart' }
  ];

  const onClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <TabContainer>
        {tabs.map((tab, idx) => (
          <li
            key={tab.title}
            className={classNames({ on: idx === currentTabIndex })}
            data-text-type={idx === currentTabIndex ? 't3' : undefined}
          >
            <Link onClick={onClick} to={`${tab.url}`}>
              {tab.title}
            </Link>
          </li>
        ))}
      </TabContainer>

      <Outlet />
    </>
  );
};
