import { useQueryString } from '#hooks/useQueryString';
import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import MiniChart from '../MiniChart';
import { useGetRecordsV2 } from '../api';
import { Box, Select } from '../styles';
import { ExerciseRecordResponseDto } from '../type';

export const ExerciseChart = () => {
  // X축 라벨 (날짜)

  const [registedLectureId] = useQueryString(['id']);

  const { data: records } = useGetRecordsV2(registedLectureId, {
    suspense: true
  });

  const [currentExercise, setCurrentExercise] =
    useState<ExerciseRecordResponseDto>();

  useEffect(() => {
    if (records && records.exerciseRecord.length > 0) {
      setCurrentExercise(records.exerciseRecord[0]);
    }
  }, [records]);

  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const exerciseKindName = e.target.value;

    if (!records) return;

    const exercise = records.exerciseRecord.find(
      item => item.exerciseKindName === exerciseKindName
    );

    if (!exercise) return;

    setCurrentExercise(exercise);
  };

  if (!records || !currentExercise) return <div></div>;

  const setType = currentExercise.sets[0].set.setType;

  const data = match(setType)
    .with('REPWEIGHT', () =>
      currentExercise.sets.map(item => item.set.weight ?? 0)
    )
    .with('REPTIME', () => currentExercise.sets.map(item => item.set.time ?? 0))
    .with('REP', () => currentExercise.sets.map(item => item.set.reps ?? 0))
    .otherwise(() => currentExercise.sets.map(item => item.set.time ?? 0));

  const unit = match(setType)
    .with('REPWEIGHT', () => 'kg')
    .with('REPTIME', () => '초')
    .with('REP', () => '회')
    .otherwise(() => '초');

  const xLabels = Array.from(Array(currentExercise.sets.length)).map(
    (_, index) => index + 1
  );

  return (
    <Box>
      <Container>
        <Select onChange={onSelectChange}>
          <option disabled>운동 이름</option>
          {records.exerciseRecord.map(item => (
            <option key={item.id} value={item.exerciseKindName}>
              {item.exerciseKindName}
            </option>
          ))}
        </Select>
        <img
          className="absolute right-[12px]"
          src="/images/ic-arrow-down-16.svg"
          alt="arrow_down"
        />
      </Container>
      <div className="w-full h-0"></div>

      <MiniChart data={data} xLabels={xLabels} legend={`(단위 : ${unit})`} />
    </Box>
  );
};

const Container = styled.div`
  display: flex;
  padding: 8px 12px 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--BG-bg-600, #2e2a3a);
  width: 100%;
  position: relative;
`;
