import { getMyAccount } from '#api/accounts';
import { postRefreshAuthToken } from '#api/auth/token';
import logo from '#assets/svg/logo.svg';
import { Text } from '#atoms';
import { PATH } from '#const/path';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import sessionStorageManager from '#utils/sessionStorageManager';
import TokenController from '#utils/tokenController';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Container, LogoArea } from './styles';

export function KakaoLoginSuccessPage() {
  const { pathname } = useLocation();

  const { showToast } = useToast();
  const { isLogIn, createSession } = useUserInfo();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const refreshToken = searchParams.get('refreshToken') ?? '';
  const { blockedPagePath, setBlockedPagePath } = sessionStorageManager();

  TokenController.setRefreshToken(refreshToken);

  useEffect(() => {
    postRefreshAuthToken()
      .then(({ accessToken, refreshToken }) => {
        createSession(
          accessToken,
          refreshToken,
          pathname === '/newSocialAccount' ? true : false
        );

        setBlockedPagePath('');
      })
      .catch(() => {
        showToast({
          type: 'fail',
          message: '카카오 로그인에 실패하였습니다.'
        });
        navigate(PATH.SIGN);
      });
  }, []);

  useEffect(() => {
    if (isLogIn) {
      getMyAccount().then(data => {
        if (data.isCoach) {
          window.location.href = `${process.env.REACT_APP_FIBUD_TRAINER_APP_URL}`;
        } else {
          navigate(blockedPagePath || PATH.HOME);
        }
      });
    }
  }, [isLogIn]);

  return (
    <Container>
      <LogoArea>
        <img src={logo} />
        <Text textStyle="t3">숫자만 세주는 트레이너 말고 진짜 전문가.</Text>
        {/* <Text textStyle="t3">PT는 피벗.</Text> */}
      </LogoArea>
    </Container>
  );
}
