import { useQueryString } from '#hooks/useQueryString';
import { useEffect } from 'react';
import { useGetRecordsV2 } from '../../api';
import { useFeedbackState } from './feedbackState';

export const ExerciseIntensity = () => {
  const list = [
    { text: '너무 쉬움' },
    { text: '쉬움' },
    { text: '보통' },
    { text: '어려움' },
    { text: '너무 어려움' }
  ];

  const [registedLectureId] = useQueryString(['id']);

  const { data } = useGetRecordsV2(registedLectureId);

  const exerciseIntensity = data?.registeredLectureRecord.exerciseIntensity;

  // 이미 있다면 상태에 저장
  useEffect(() => {
    if (exerciseIntensity) {
      setRatingState(prev => ({ ...prev, exerciseIntensity }));
    }
  }, [exerciseIntensity]);

  // 없다면 입력받은 값을 상태에 저장
  const { ratingState, setRatingState } = useFeedbackState();

  const onClick = (index: number) => {
    if (ratingState.isSuccess || exerciseIntensity) return;

    setRatingState(prev => ({ ...prev, exerciseIntensity: index }));
  };

  return (
    <div className="w-full">
      <div className="flex gap-4">
        <div className="text-[#938f9e] text-base font-medium font-['Pretendard Variable'] leading-[21px]">
          운동 강도
        </div>
        <div className="text-[#e13a3a] text-lg font-medium font-['Pretendard Variable'] leading-snug">
          *
        </div>
      </div>
      <div className="w-full h-8"></div>
      <div className="flex gap-8 w-full">
        {list.map((item, index) => (
          <div
            className="flex w-full"
            key={item.text}
            onClick={() => onClick(index + 1)}
          >
            <ExerciseIntensityItem
              index={index}
              isSelected={
                index + 1 === ratingState.exerciseIntensity ||
                index + 1 === exerciseIntensity
              }
              text={item.text}
              isSuccess={ratingState.isSuccess}
              hasData={!!exerciseIntensity}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const ExerciseIntensityItem = ({
  isSelected,
  isSuccess,
  index,
  text,
  hasData
}: {
  isSelected: boolean;
  index: number;
  text: string;
  isSuccess?: boolean;
  hasData?: boolean; // api로 받아온 데이터가 있는지
}) => {
  const disabled = (isSuccess && !isSelected) || (hasData && !isSelected);

  return (
    <div
      style={{
        backgroundColor: disabled ? '#746f7e' : '#2e2a3a',
        borderColor: isSelected ? '#7750e9' : 'transparent'
      }}
      className="flex-1 h-[71px] py-4  rounded-lg border-1 border-solid flex-col justify-center items-center gap-1 inline-flex"
    >
      <div className="text-[#bcb8c3] text-[17px] font-medium font-['Pretendard Variable'] leading-snug">
        {index + 1}
      </div>
      <div
        style={{
          color: disabled ? '#938f9e' : '#746f7e'
        }}
        className="text-[10px] font-normal font-['Pretendard Variable'] leading-[13px] tracking-tight"
      >
        {text}
      </div>
    </div>
  );
};
