import { Button, Text } from '#atoms';
import { useNavigate } from 'react-router';
import { ButtonContainer, Container, TextContainer } from './styles';

export function FindCoachTemplate() {
  const navigate = useNavigate();
  return (
    <Container>
      <TextContainer>
        <Text textStyle="t3">
          아직 수업 정보가 없어요!
          <br />
          수업을 등록하고 이용해보세요
        </Text>
      </TextContainer>
      <ButtonContainer
        onClick={() => {
          navigate(`/expert`);
        }}
      >
        <Button>내 주변 전문가 보기</Button>
      </ButtonContainer>
    </Container>
  );
}
