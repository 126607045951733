import { Divider } from '../styles';
import { Comment } from './FeedbackSection/Comment';
import { ExerciseIntensity } from './FeedbackSection/ExerciseIntensity';
import { SubmitButton } from './FeedbackSection/SubmitButton';
import { RatingSection } from './RatingSection';

export const Rating = () => {
  return (
    <div>
      <RatingSection />
      <Divider />
      <div className="px-20 py-32">
        <div className="flex w-full justify-between">
          <div className="text-[#d7d2de] text-[18px] font-medium font-['Pretendard Variable']">
            수업 피드백
          </div>
          <SubmitButton />
        </div>
        <div className="w-full h-16"></div>

        <ExerciseIntensity />

        <div className="w-full h-20"></div>

        <Comment />
      </div>
    </div>
  );
};
