import { useQueryString } from '#hooks/useQueryString';
import { Panel } from '../../Panel';
import { useGetRecordsV2 } from '../../api';

import { ExerciseMedia } from './ExerciseMedia';
import { SetList } from './SetList';
import { Title } from './Title';

export const ChartExerciseList = () => {
  const [registedLectureId] = useQueryString(['id']);

  const { data } = useGetRecordsV2(registedLectureId, {
    suspense: true
  });

  if (!data) return <div></div>;

  return (
    <div className="flex flex-col gap-12">
      {data.exerciseRecord.map((item, index) => {
        return (
          <Panel
            title={'운동내역'}
            Title={
              <Title
                index={index}
                name={item.exerciseKindName}
                workout={item}
              />
            }
          >
            <SetList
              sets={item.sets.map(set => ({
                createdAt: set.set.createdAt,
                updatedAt: set.set.updatedAt,
                setType: set.set.setType,
                weight: set.set.weight,
                reps: set.set.reps,
                time: set.set.time,
                round: set.set.round
              }))}
            />

            {item.images.length > 0 ||
              (item.videos.length > 0 && (
                <ExerciseMedia images={item.images} videos={item.videos} />
              ))}
          </Panel>
        );
      })}
    </div>
  );
};
