import { ExerciseRecordResponseDto } from '../../type';

interface TitleProps {
  index: number;
  workout: ExerciseRecordResponseDto;
  name: string;
}
export const Title = ({ index, workout, name }: TitleProps) => {
  //   const part = currentExerciseType?.part;

  const totalSet = workout.sets.length;

  const totalWeight = workout.sets.reduce(
    (acc, cur) =>
      acc + (Number(cur.set.weight) || 0) * (Number(cur.set.reps) || 0),
    0
  );

  // 최고 회수
  const maxReps = Math.max(
    ...workout.sets.map(set => Number(set.set.reps) || 0)
  );

  // 최고 시간
  const maxTime = Math.max(
    ...workout.sets.map(set => Number(set.set.time) || 0)
  );

  // 최고 중량
  const maxWeight = Math.max(
    ...workout.sets.map(set => Number(set.set.weight) || 0)
  );

  // 최고 중량인 세트를 찾아서 무게 * 시간 형식으로 반환
  const repsWeight = workout.sets
    .filter(set => Number(set.set.weight) === maxWeight)
    .map(set => `${set.set.weight}kg x ${set.set.reps}회`)
    .join(' / ');

  let total = '';

  switch (workout.sets[0].set.setType) {
    case 'REP':
      total = `총 ${totalSet}세트, ${maxReps}회`;
      break;
    case 'REPWEIGHT':
      total = `총 ${totalSet}세트, ${repsWeight}, 총 ${totalWeight.toLocaleString(
        'ko-KR'
      )}kg`;
      break;
    case 'REPTIME':
      total = `총 ${totalSet}세트,${maxTime}초, ${maxReps}회`;
      break;
    case 'TIME':
      total = `총 ${totalSet}세트, ${maxTime}초`;
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex gap-8 items-center">
        <div
          style={{
            display: 'flex',
            width: '20px',
            height: '20px',
            padding: '4px 8px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            borderRadius: '4px',
            background: 'var(--bg-500, #373245)'
          }}
        >
          <div
            style={{
              color: 'var(--Gray-Scale-500, #948F9E)',
              textAlign: 'center',
              fontFamily: '"Pretendard Variable"',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '16px'
            }}
          >
            {index + 1}
          </div>
        </div>
        <div className="flex gap-10">
          <div className="text-[#e7e3ee] text-base font-normal font-['Pretendard Variable'] leading-[1]">
            {name}
          </div>
          {/* <div className="text-[#9170f2] text-sm font-normal font-['Pretendard Variable']">
              {part}
            </div> */}
        </div>
      </div>
      <div className="text-[#746f7e] text-sm font-normal font-['Pretendard Variable']">
        {total}
      </div>
    </div>
  );
};
