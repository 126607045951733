import { useState } from 'react';
import styled from 'styled-components';

interface PanelProps {
  title: string;
  Title?: JSX.Element;
  children?: React.ReactNode;
  required?: boolean;
  isOpen?: boolean;
}

/**
 * 접고 펼 수 있는 패널 컴포넌트
 * @param title 패널 제목
 * @param children 패널 내용
 * @param required 필수여부를 나타내는 컴포넌트가 표시됨
 * @param isOpen 패널이 열려있는지 여부
 */
export const Panel = ({
  title,
  children,
  required,
  isOpen: defaultIsOpen = false,
  Title
}: PanelProps) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  return (
    <div>
      <div
        className="py-16 px-20 bg-[#252230] rounded-xl"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex justify-between items-center">
          <div className="flex gap-4">
            {Title ? (
              Title
            ) : (
              <div className="text-[#d7d2de] text-base font-medium font-['Pretendard Variable']">
                {title}
              </div>
            )}

            {required && (
              <div className="text-[#e13a3a] text-base font-medium font-['Pretendard Variable']">
                *
              </div>
            )}
          </div>
          <ArrowContainer isOpen={isOpen}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18 17L12 11L6 17L4 15L12 7L20 15L18 17Z"
                fill="#595463"
              />
            </svg>
          </ArrowContainer>
        </div>
      </div>
      {isOpen && <div className="px-20 py-12">{children}</div>}
    </div>
  );
};

// isOpen에 따라 180도씩 회전하는 화살표 컴포넌트
const ArrowContainer = styled.div<{ isOpen: boolean }>`
  width: 24px;
  height: 24px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(0)' : 'rotate(180deg)')};
`;
