import { atom, useRecoilState } from 'recoil';

const $feedbackState = atom<{
  exerciseIntensity: number;
  userComment?: string;
  isSuccess?: boolean;
}>({
  key: 'feedbackState',
  default: {
    exerciseIntensity: 0,
    userComment: '',
    isSuccess: false
  }
});

export const useFeedbackState = () => {
  const [ratingState, setRatingState] = useRecoilState($feedbackState);

  return {
    ratingState,
    setRatingState
  };
};
