import { useQueryString } from '#hooks/useQueryString';
import { useEffect } from 'react';
import { useGetRecordsV2 } from '../../api';
import { Stars } from './Stars';
import { useRatingState } from './ratingState';

interface StarRatingProps {
  disabled?: boolean;
}

export const StarRating = ({ disabled }: StarRatingProps) => {
  const [registedLectureId] = useQueryString(['id']);

  const { data } = useGetRecordsV2(registedLectureId);

  const coachNice = data?.lectureRate?.coachNice;
  const timeNice = data?.lectureRate?.timeNice;
  const lectureQuality = data?.lectureRate?.lectureQuality;

  useEffect(() => {
    if (coachNice) {
      setRatingState(prev => ({ ...prev, coachNice }));
    }

    if (timeNice) {
      setRatingState(prev => ({ ...prev, timeNice }));
    }

    if (lectureQuality) {
      setRatingState(prev => ({ ...prev, lectureQuality }));
    }
  }, [coachNice, timeNice, lectureQuality]);

  const { ratingState, setRatingState } = useRatingState();

  const list = [
    { text: '코치 친절도', key: 'coachNice' },
    { text: '시간 약속', key: 'timeNice' },
    { text: '수업 전문성', key: 'lectureQuality' }
  ];

  return (
    <div className="flex flex-col gap-16">
      {list.map((item, index) => (
        <div key={item.text} className="flex justify-between">
          <div className="text-[#938f9e] text-base font-normal font-['Pretendard Variable'] leading-[21px]">
            {item.text}
          </div>
          <Stars
            index={
              ratingState[
                item.key as 'coachNice' | 'timeNice' | 'lectureQuality'
              ]
            }
            onClick={index => {
              if (disabled) return;
              setRatingState(prev => ({ ...prev, [item.key]: index }));
            }}
          />
        </div>
      ))}
    </div>
  );
};
