import styled from 'styled-components';

export const LocationArea = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  border-radius: 0.8rem;
  color: var(--color-gray-text-second);

  > span {
    cursor: pointer; // BoxIcon
  }

  > p {
    cursor: pointer; // Text
  }
`;
