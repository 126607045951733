import axios from '#api/axios';
import { useQuery } from '@tanstack/react-query';

interface MemberInbodyHistoryResponseDto {
  weightHistory: Array<{ [key: string]: number }>;
  bodyFatMassHistory: Array<{ [key: string]: number }>;
  skeletalMuscleMassHistory: Array<{ [key: string]: number }>;
}

const getInbodyHistory = async (registeredLectureId: string) => {
  const { data } = await axios<MemberInbodyHistoryResponseDto>({
    method: 'GET',
    url: `/records/${registeredLectureId}/inbody-history`
  });

  return data;
};

export const useGetInbodyHistory = (registeredLectureId: string) => {
  return useQuery(
    ['inbody-history', registeredLectureId],
    () => getInbodyHistory(registeredLectureId),
    {
      enabled: !!registeredLectureId
    }
  );
};

interface PainHistoryResponseDto {
  painHistory: {
    [bodyPart: string]: {
      [date: string]: number;
    };
  };
}

const getPainHistory = async (registeredLectureId: string) => {
  const { data } = await axios<PainHistoryResponseDto>({
    method: 'GET',
    url: `/records/${registeredLectureId}/pain-parts-history`
  });

  return data;
};

export const useGetPainHistory = (registeredLectureId: string) => {
  return useQuery(
    ['pain-history', registeredLectureId],
    () => getPainHistory(registeredLectureId),
    {
      enabled: !!registeredLectureId
    }
  );
};
