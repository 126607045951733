import { EditButton } from './EditButton';
import { StarRating } from './StarRating';

export const RatingSection = () => {
  return (
    <div className="px-20 pb-32">
      <div className="flex justify-between">
        <div className="text-[#d7d2de] text-[18px] font-medium font-['Pretendard Variable']">
          평점
        </div>
        <EditButton />
      </div>
      <div className="w-full h-16"></div>
      <StarRating disabled />
    </div>
  );
};
