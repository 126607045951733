import styled from 'styled-components';

const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
  width: 50%;
  background-color: var(--color-point);
  img {
    margin: auto;
  }
`;

export { Container };
