import { useQueryString } from '#hooks/useQueryString';
import styled from 'styled-components';
import { useGetRecordsV2 } from '../api';

export const InbodyIndicator = () => {
  const [registedLectureId] = useQueryString(['id']);

  const { data } = useGetRecordsV2(registedLectureId);

  if (!data) return <div></div>;

  const { physicalRecordDto } = data;
  const { inbody } = physicalRecordDto;

  const list = [
    { key: '체중', value: inbody, unit: 'kg' },
    { key: '체지방률', value: undefined, unit: '%' },
    { key: '골격근량', value: undefined, unit: 'kg' }
  ];

  return (
    <div className="flex w-full py-2">
      {list.map((item, index) => (
        <div
          className="flex-1 flex flex-col gap-12 justify-center items-center border-r-1 border-[#262231] border-solid last:border-r-0"
          key={item.key}
        >
          <Key>{item.key}</Key>
          <div className="flex items-end gap-2">
            <Value>{item.value ?? '-'}</Value>
            <Unit>{item.unit}</Unit>
          </div>
        </div>
      ))}
    </div>
  );
};

const Key = styled.div`
  color: var(--Gray-Scale-600, #756f7e);

  /* NEW/caption_01_regular */
  font-family: 'Pretendard Variable';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
`;

const Value = styled.div`
  color: var(--Gray-Scale-400, #bcb8c3);

  /* NEW/body_02_medium */
  font-family: 'Pretendard Variable';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;

const Unit = styled.div`
  color: var(--Gray-Scale-500, #948f9e);

  /* NEW/caption_01_regular */
  font-family: 'Pretendard Variable';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
`;
