import { useQueryString } from '#hooks/useQueryString';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useGetRecordsV2 } from '../../api';
import { useFeedbackState } from './feedbackState';

export const Comment = () => {
  const [registedLectureId] = useQueryString(['id']);

  const { data } = useGetRecordsV2(registedLectureId);

  const userComment = data?.registeredLectureRecord.userComment;

  // 이미 코멘트가 있다면 코멘트를 불러와서 상태에 저장
  useEffect(() => {
    if (userComment) {
      setRatingState(prev => ({ ...prev, userComment }));
    }
  }, [userComment]);

  // 없다면 입력받은 코멘트를 상태에 저장
  const { ratingState, setRatingState } = useFeedbackState();

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRatingState(prev => ({ ...prev, userComment: e.target.value }));
  };

  // 입력하여 전송완료이거나 이미 있거나
  const disabled = ratingState.isSuccess || !!userComment;

  return (
    <div className="flex flex-col gap-8">
      <div className="text-[#938f9e] text-base font-medium font-['Pretendard Variable'] leading-[21px]">
        코멘트
      </div>
      <div className="w-full min-h-[240px] p-16 bg-[#252230] rounded-lg flex-col justify-start items-start gap-2.5 inline-flex">
        <TextArea
          disabled={disabled}
          value={ratingState.userComment}
          onChange={onChange}
          placeholder="코멘트를 입력해주세요"
        />
      </div>
    </div>
  );
};

const TextArea = styled.textarea`
  resize: none;
  outline: none;
  background-color: transparent;
  border: none;
  width: 100%;
  color: var(--Gray-Scale-300, #d7d3de);

  /* NEW/body_02_regular */
  font-family: 'Pretendard Variable';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;
