import { useQueryString } from '#hooks/useQueryString';
import { useToast } from '#providers/ToastProvider';
import styled from 'styled-components';
import { useGetRecordsV2 } from '../../api';
import { usePostRecordsFeedback } from './api';
import { useFeedbackState } from './feedbackState';

export const SubmitButton = () => {
  const [registedLectureId] = useQueryString(['id']);

  const { data } = useGetRecordsV2(registedLectureId);

  const { showToast } = useToast();

  const { ratingState, setRatingState } = useFeedbackState();

  const { mutate, isSuccess } = usePostRecordsFeedback({
    onSuccess: () => {
      setRatingState(prev => ({ ...prev, isSuccess: true }));
      showToast({
        type: 'success',
        message: '전송완료'
      });
    },
    onError: () => {
      showToast({
        type: 'fail',
        message: '전송실패'
      });
    }
  });

  const onSubmit = () => {
    mutate({
      params: {
        exerciseIntensity: ratingState.exerciseIntensity,
        userComment: ratingState.userComment
      },
      registeredLectureId: registedLectureId
    });
  };

  const disabled = isSuccess;

  const text = isSuccess ? '전송완료' : '전송';

  return (
    <>
      {!(
        !!data?.registeredLectureRecord.userComment ||
        !!data?.registeredLectureRecord.exerciseIntensity
      ) && (
        <Button disabled={disabled} isSubmit={isSuccess} onClick={onSubmit}>
          {text}
        </Button>
      )}
    </>
  );
};

const Button = styled.button<{ isSubmit: boolean }>`
  color: ${({ isSubmit }) =>
    isSubmit ? '#595463' : 'var(--Brand-300, var(--C79EF0, #9170f2));'};

  /* NEW/body_02_medium */
  font-family: 'Pretendard Variable';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.32px;
`;
