import { useGetRegisteredLecture } from '#api/registered-lectures';
import {
  calculateTimeDifference,
  dateDayKoFormatter,
  formatTimeRange
} from '#utils/dateFormatter';

import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

export const InfoSection = () => {
  const [searchParams] = useSearchParams();
  const registeredLectureId = searchParams.get('id');

  const { data: registedLecture } = useGetRegisteredLecture(
    registeredLectureId as string
  );

  if (!registedLecture) {
    return <div></div>;
  }

  function formatDateString(input: string): string {
    // 요일 축약형 매핑
    const dayMap: { [key: string]: string } = {
      일요일: '일',
      월요일: '월',
      화요일: '화',
      수요일: '수',
      목요일: '목',
      금요일: '금',
      토요일: '토'
    };

    // 정규식을 이용해 날짜와 요일 추출
    const regex =
      /^(\d{4})년 (\d{1,2})월 (\d{1,2})일 (일요일|월요일|화요일|수요일|목요일|금요일|토요일)$/;
    const match = input.match(regex);

    if (match) {
      const year = match[1];
      const month = match[2].padStart(2, '0'); // 두 자리수로 변환
      const day = match[3].padStart(2, '0'); // 두 자리수로 변환
      const dayOfWeek = match[4];

      // 요일 축약형 변환
      const shortDayOfWeek = dayMap[dayOfWeek];

      // 결과 문자열 반환
      return `${year}.${month}.${day} (${shortDayOfWeek})`;
    } else {
      // 매치되지 않으면 입력을 그대로 반환
      return input;
    }
  }

  return (
    <div className="flex flex-col gap-20 py-28 px-20">
      <div className="flex flex-col gap-4">
        <div className="flex gap-8">
          <DateTime>
            {formatDateString(dateDayKoFormatter(registedLecture.taughtAt))}
          </DateTime>
          <DateTime>
            {formatTimeRange(registedLecture.taughtAt, registedLecture.endedAt)}
          </DateTime>
        </div>
        <div className="flex gap-4">
          <LectureTitle>{registedLecture.title}</LectureTitle>
          <LectureRound>{registedLecture.round}회차</LectureRound>
        </div>
      </div>
      <div className="flex flex-col gap-12">
        <div className="flex gap-8 items-center">
          <div className="rounded-[50%] overflow-hidden">
            <img
              src={registedLecture.coachProfile[0]}
              alt="트레이너 프로필 이미지"
              width={28}
              height={28}
            />
          </div>
          <CoachName>{registedLecture.coachName}</CoachName>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex gap-8">
            <img src="/images/ic-time.svg" alt="총 시간" />
            <TotalTime>
              {calculateTimeDifference(
                registedLecture.taughtAt,
                registedLecture.endedAt
              )}
            </TotalTime>
          </div>
          <div className="flex gap-8">
            <img src="/images/ic-location-24.svg" alt="헬스장 이름 · 지역" />
            <GymInfo>
              ${registedLecture.gym?.name} · {registedLecture.gym?.address}
            </GymInfo>
          </div>
        </div>
      </div>
    </div>
  );
};

const DateTime = styled.div`
  color: var(--Gray-Scale-500, #948f9e);

  /* NEW/body_02_regular */
  font-family: 'Pretendard Variable';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

const LectureTitle = styled.div`
  color: var(--Gray-Scale-300, #d7d3de);

  /* NEW/headline_01_medium */
  font-family: 'Pretendard Variable';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
`;

const LectureRound = styled.div`
  color: var(--Brand-400, #7750e9);

  /* NEW/headline_01_medium */
  font-family: 'Pretendard Variable';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
`;

const CoachName = styled.div`
  color: var(--Gray-Scale-400, #bcb8c3);

  /* NEW/body_02_medium */
  font-family: 'Pretendard Variable';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;

const TotalTime = styled.div`
  color: var(--Gray-Scale-600, #756f7e);

  /* NEW/body_03_regular */
  font-family: 'Pretendard Variable';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

const GymInfo = styled.div`
  color: var(--Gray-Scale-600, #756f7e);

  /* NEW/body_03_regular */
  font-family: 'Pretendard Variable';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;
