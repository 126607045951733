import { useSearchParams } from 'react-router-dom';

/**
 * 모든 쿼리 스트링을 반환하는 커스텀 훅
 * @returns
 */
export const useQueryString = (queries: string[]): string[] => {
  const [searchParams] = useSearchParams();

  // queries 배열에 있는 각 쿼리 키에 대해 값을 추출하여 배열로 반환
  const values = queries.map(query => searchParams.get(query) || '');

  return values;
};
