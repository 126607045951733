import { atom, useRecoilState } from 'recoil';

export interface RatingState {
  coachNice: number;
  timeNice: number;
  lectureQuality: number;
}

const $ratingState = atom<RatingState>({
  key: 'ratingState',
  default: {
    coachNice: 0,
    timeNice: 0,
    lectureQuality: 0
  }
});

export const useRatingState = () => {
  const [ratingState, setRatingState] = useRecoilState($ratingState);

  return {
    ratingState,
    setRatingState
  };
};
