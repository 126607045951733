import { PATH } from '#const/path';
import { useLectureChart } from '#providers/LectureChart';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ExerciseRecord } from './ExerciseRecord';
import { HealthRecord } from './HealthRecord';
import { InfoSection } from './InfoSection';
import { Rating } from './Rating';
import { useFeedbackState } from './Rating/FeedbackSection/feedbackState';
import { LectureRatingModal } from './Rating/LectureRatingModal';
import { useRatingState } from './Rating/RatingSection/ratingState';
import { Tab } from './Tab';

export const RecordDetailPage = () => {
  const { setRegisteredLectureId, record } = useLectureChart();
  const [searchParams] = useSearchParams();
  const registeredLectureId = searchParams.get('id');

  const { setRatingState } = useRatingState();
  const { setRatingState: setFeedbackState } = useFeedbackState();

  useEffect(() => {
    registeredLectureId && setRegisteredLectureId(registeredLectureId);
  }, [registeredLectureId]);

  const tabList = ['운동 기록', '건강 기록', '평가'];

  const [selectedTab, setSelectedTab] = useState<string>(tabList[0]);

  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={`${PATH.CHART_LECTURE}?id=${record.registeredLectureRecord?.lectureId}`}
      title="운동차트"
      defaultBackground
      onPrevCallback={() => {
        setRatingState({ coachNice: 0, timeNice: 0, lectureQuality: 0 });
        setFeedbackState({ exerciseIntensity: 0, userComment: '' });
      }}
    >
      <div className="h-217">
        <InfoSection />
      </div>
      <Tab
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        tabList={['운동 기록', '건강 기록', '평가']}
      />

      <div className="h-24 w-full"></div>
      {selectedTab === '운동 기록' && <ExerciseRecord />}
      {selectedTab === '건강 기록' && <HealthRecord />}
      {selectedTab === '평가' && <Rating />}

      <LectureRatingModal />
    </PrevHeaderBarWithTitleTemplate>
  );
};
