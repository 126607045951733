import { getMyAccount } from '#api/accounts';
import { postSignin } from '#api/auth/signin';
import { Button, Input, Text } from '#atoms';
import { PATH } from '#const/path';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { PrevWithTitleTextTemplate } from '#templates';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner';
import { ButtonArea, OptionContainer, SignInButtonContainer } from './styles';

export const SignInPage = () => {
  const { showToast } = useToast();

  const [loading, setLoading] = useState<boolean>();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { mutateAsync: signinMutation } = useMutation(postSignin);
  const { isLogIn, createSession } = useUserInfo();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = () => {
    if (!email || !password) return;

    signinMutation(
      { email, password },
      {
        onSuccess: ({ accessToken, refreshToken }) => {
          setLoading(true);
          createSession(accessToken, refreshToken);
        }
      }
    ).catch(error =>
      showToast({
        type: 'fail',
        message: '이메일 또는 비밀번호를 확인해주세요.'
      })
    );
  };

  useEffect(() => {
    if (isLogIn) {
      getMyAccount().then(data => {
        if (data.isCoach) {
          window.location.href = `${
            process.env.REACT_APP_FIBUD_TRAINER_APP_URL as string
          }`;
        } else {
          navigate(location.state?.blockedPagePath ?? PATH.HOME);
        }
      });
    }
  }, [isLogIn]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <PrevWithTitleTextTemplate title="로그인">
      <form autoComplete="off">
        <ButtonArea>
          <Input
            type="text"
            placeholder="이메일 주소를 입력해주세요"
            labelText="이메일"
            value={email}
            onChange={v => setEmail(String(v))}
          />
          <Input
            type="password"
            placeholder="비밀번호를 입력해주세요"
            labelText="비밀번호"
            value={password}
            onChange={v => setPassword(String(v))}
          />
        </ButtonArea>
      </form>
      <SignInButtonContainer>
        <Button onClick={handleSubmit}>로그인</Button>
        <OptionContainer>
          <Text onClick={() => navigate(PATH.SIGNUP)}>회원가입 하기</Text>|
          <Text onClick={() => navigate(PATH.SIGNIN_PASSWORD_REQUEST)}>
            비밀번호 찾기
          </Text>
        </OptionContainer>
      </SignInButtonContainer>
    </PrevWithTitleTextTemplate>
  );
};
