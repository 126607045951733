import { Text } from '#atoms';
import { Container } from './styles';
import CertificationMarkIcon from '#assets/svg/Certification-Icon.svg';

interface ExpertNameBoxProps {
  name: string;
  isCertification?: boolean;
}

export const ExpertNameBox = ({
  name,
  isCertification
}: ExpertNameBoxProps) => {
  return (
    <Container>
      <Text
        style={{
          fontSize: '1.6rem',
          fontWeight: 600,
          lineHeight: '140%'
        }}
      >
        {name} 전문가
      </Text>
      {isCertification ? <img src={CertificationMarkIcon} /> : ''}
    </Container>
  );
};
