import { useQueryString } from '#hooks/useQueryString';
import { useState } from 'react';
import styled from 'styled-components';
import MiniChart from '../MiniChart';
import { Box } from '../styles';
import { formatDateToMMDD } from '../utils';
import { useGetInbodyHistory } from './api';

type Key = 'weightHistory' | 'bodyFatMassHistory' | 'skeletalMuscleMassHistory';

export const InbodyChart = () => {
  const [registedLectureId] = useQueryString(['id']);

  const { data } = useGetInbodyHistory(registedLectureId);

  // const data = {
  //   weightHistory: [
  //     {
  //       '2024-05-30T22:00:00.000Z': 60
  //     },
  //     {
  //       '2024-06-01T00:00:00.000Z': 57
  //     }
  //   ],
  //   bodyFatMassHistory: [
  //     {
  //       '2024-05-30T22:00:00.000Z': 25
  //     },
  //     {
  //       '2024-06-01T00:00:00.000Z': 22
  //     }
  //   ],
  //   skeletalMuscleMassHistory: [
  //     {
  //       '2024-05-30T22:00:00.000Z': 19
  //     },
  //     {
  //       '2024-06-01T00:00:00.000Z': 21
  //     }
  //   ]
  // };

  const [selectedInbodyInfo, setSelectedInbodyInfo] =
    useState<Key>('weightHistory');

  if (!data) return <div></div>;

  if (
    data.bodyFatMassHistory.length === 0 &&
    data.skeletalMuscleMassHistory.length === 0 &&
    data.weightHistory.length === 0
  ) {
    return <Box>인바디 정보가 없습니다.</Box>;
  }

  const inbodyInfo = data[selectedInbodyInfo]
    .map(inbodyInfo => {
      const [date, value] = Object.entries(inbodyInfo)[0]; // Get the first entry in the object
      return { date, value };
    })
    .map(inbodyInfo => inbodyInfo.value);

  const xLabels = data[selectedInbodyInfo]
    .map(inbodyInfo => {
      const [date, value] = Object.entries(inbodyInfo)[0]; // Get the first entry in the object
      return { date, value };
    })
    .map(inbodyInfo => formatDateToMMDD(inbodyInfo.date));

  return (
    <Box>
      <div className="w-full h-0"></div>

      <MiniChart data={inbodyInfo} xLabels={xLabels} />

      <div className="w-full h-40"></div>
      <div className="w-full gap-8 items-center flex">
        <TabList
          selectedTab={selectedInbodyInfo}
          setSelectedTab={setSelectedInbodyInfo}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M5 11C5 10.4477 5.44772 10 6 10H10C10.5523 10 11 10.4477 11 11V19C11 19.5523 10.5523 20 10 20H6C5.44772 20 5 19.5523 5 19V11Z"
            fill="#E13A3A"
          />
          <rect x="13" y="4" width="6" height="16" rx="1" fill="#4CAF50" />
        </svg>
      </div>
    </Box>
  );
};

interface TabListProps {
  selectedTab: Key;
  setSelectedTab: (tab: Key) => void;
}
const TabList = ({ selectedTab, setSelectedTab }: TabListProps) => {
  const tabList = [
    { name: '체중', key: 'weightHistory' },
    { name: '체지방률', key: 'bodyFatMassHistory' },
    { name: '골격근량', key: 'skeletalMuscleMassHistory' }
  ];

  return (
    <div className="flex w-full">
      {tabList.map(tab => {
        return (
          <StyledTab
            onClick={() => setSelectedTab(tab.key as Key)}
            isSelected={tab.key === selectedTab}
            key={tab.key}
          >
            {tab.name}
          </StyledTab>
        );
      })}
    </div>
  );
};
const StyledTab = styled.div<{ isSelected: boolean }>`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  border-radius: 8px;
  background-color: ${props =>
    props.isSelected ? 'var(--gray-600---border2, #2E2B36)' : 'transparent'};

  color: ${props =>
    props.isSelected
      ? 'var(--gray-15---main-font, #eae8ed)'
      : 'var(--gray-300---disabled, #5C576B)'};
  text-align: center;

  /* Body 3/SB */
  font-family: 'Pretendard Variable';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.5px;
`;
