import styled from 'styled-components';

type SetType = 'REPWEIGHT' | 'REPTIME' | 'TIME' | 'REP';

interface SetListProps {
  sets: {
    createdAt: string; // 생성일자
    updatedAt: string; // 수정일자
    setType: SetType; // 운동 방식
    weight?: number; // 무게
    reps?: number; // 횟수
    time?: number; // 운동 진행 시간 (단위: 분)
    round: number; // 해당 세트 진행 횟수
  }[];
}
export const SetList = ({ sets }: SetListProps) => {
  return (
    <div className="flex flex-col gap-8">
      {sets.map((set, index) => {
        const { round, weight, time, reps } = set;
        return (
          <div key={index} className="flex justify-between py-8">
            <div className="flex gap-2">
              <Value>{round}</Value>
              <Unit>세트</Unit>
            </div>

            {weight && Number(weight) > 0 && (
              <div className="flex gap-2">
                <Value>{weight}</Value>
                <Unit>kg</Unit>
              </div>
            )}

            {time && time > 0 && (
              <div className="flex gap-2">
                <Value>{time}</Value>
                <Unit>sec</Unit>
              </div>
            )}

            {reps && reps > 0 && (
              <div className="flex gap-2">
                <Value>{reps}</Value>
                <Unit>회</Unit>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const Unit = styled.div`
  color: var(--Gray-Scale-600, #756f7e);

  /* NEW/body_03_medium */
  font-family: 'Pretendard Variable';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.5px;
`;

const Value = styled.div`
  color: var(--Gray-Scale-400, #bcb8c3);

  /* NEW/headline_01_medium */
  font-family: 'Pretendard Variable';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
  letter-spacing: -0.408px;
`;
