import { TabListViewer } from '#molecules';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import MetaHelmet from '#utils/MetaHelmet';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ProfileViewer } from 'src/components/templates/CoachDetailContent/ProfileViewer';
import {
  useGetEducationDetail,
  useGetEducationIssuerId
} from 'src/domain/education/api';
import { EduCoachesList } from 'src/domain/education/components/EduCoachList';
import { EduIntroduce } from 'src/domain/education/components/EduIntroduce';

export const EduPage = () => {
  // 쿼리 파라미터 가져오기
  const { educationUrl } = useParams();

  const { data: educationIssuerId } = useGetEducationIssuerId(educationUrl, {
    enabled: !!educationUrl
  });
  const { data: educationDetail } = useGetEducationDetail(
    educationIssuerId as string,
    { enabled: !!educationIssuerId }
  );

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const onTabChange = (tab: number) => {
    const searchParams = new URLSearchParams();
    searchParams.set('tab', tab.toString());
    navigate(
      {
        search: searchParams.toString()
      },
      {
        replace: true
      }
    );
  };

  return (
    <>
      <MetaHelmet
        title={educationDetail?.name}
        url={'https://fibud.kr/team/' + educationUrl}
        img={educationDetail?.images[0]?.uploadedLink ?? './ms-icon-70x70.png'}
      />
      <PrevHeaderBarWithTitleTemplate title={educationDetail?.name ?? '교육'}>
        {educationDetail?.images && (
          <ProfileViewer
            imgSrcList={educationDetail?.images.map(
              image => image.uploadedLink
            )}
            alt="교육 소개 이미지"
          ></ProfileViewer>
        )}
        <TabListViewer
          initTab={tab ? Number(tab) : 0}
          onTabChange={onTabChange}
        >
          <TabListViewer.List tabs={['소개', '수료 전문가']} />
          <TabListViewer.Viewer
            tabs={[
              <>
                {educationDetail && (
                  <EduIntroduce educationDetail={educationDetail} />
                )}
              </>,
              <EduCoachesList />
            ]}
          />
        </TabListViewer>
      </PrevHeaderBarWithTitleTemplate>
    </>
  );
};
