import { Divider } from '../styles';
import { InbodyIndicator } from './InbodyIndicator';
import { InbodyChart } from './InobdyChart';
import { PainChart } from './PainChart';

export const HealthRecord = () => {
  return (
    <div>
      <div className="px-20 pb-32">
        <div className="text-[#d7d2de] text-[18px] font-medium font-['Pretendard Variable']">
          인바디
        </div>
        <div className="w-full h-16"></div>
        <InbodyIndicator />
        <div className="w-full h-20"></div>
        <InbodyChart />
      </div>
      <Divider />
      <div className="px-20 py-32">
        <div className="text-[#d7d2de] text-[18px] font-medium font-['Pretendard Variable']">
          통증
        </div>
        <div className="w-full h-16"></div>
        <PainChart />
      </div>
    </div>
  );
};
