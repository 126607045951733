import styled from 'styled-components';

const NotFoundPage = () => {
  return (
    <Container>
      <svg
        width="80"
        height="80"
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="100" cy="100" r="80" fill="#333" />
        <circle cx="100" cy="60" r="12" fill="#b0b0b0" />
        <rect x="92" y="85" width="16" height="56" fill="#b0b0b0" />
      </svg>

      <NotFound>찾으시는 페이지가 없습니다.</NotFound>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100dvh;
  justify-content: center;
`;

const NotFound = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  color: var(--Gray-Scale-500, #948f9e);

  /* NEW/body_01_medium */
  font-family: 'Pretendard Variable';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.41px;
`;

export default NotFoundPage;
