import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 1.6rem;
  // min-height: 20rem;
  height: 20rem;
`;

export { Container };
