import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 0.6rem;
  align-items: center;
  img {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

export { Container };
