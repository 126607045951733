import { useGetLicenseCoachList } from '#hooks/quries/license/useGetLicenseCoachList';
import {
  ILicenseIntroduce,
  useGetLicenseIntroduce
} from '#hooks/quries/license/useGetLicenseIntroduce';
import { useGetLicenseIssuerId } from '#hooks/quries/license/useGetLicenseIssureId';
import { useGeolocation } from '#hooks/useGeolocation';
import { useIntersectionObserver } from '#hooks/useIntersectionObserver';
import useScrollRestoration from '#hooks/useScrollRestoration';
import { LocationItem, TabListViewer } from '#molecules';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import MetaHelmet from '#utils/MetaHelmet';
import { useNavigate, useParams } from 'react-router';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner';
import { TabIntroduce } from 'src/components/molecules/TabIntroduce';
import { LoadingWrapper } from 'src/components/organisims/CoachGridSelector/styles';
import { CoacheList } from 'src/components/organisims/CoacheList/CoacheList';
import { ProfileViewer } from 'src/components/templates/CoachDetailContent/ProfileViewer';

const noneRegisterMessage = '등록되지 않은 자격증입니다';
const noneRegisterRoute = '/';

/**
 * @api 1. [get] /license-issuers/licenseIssureId?licenseUrl=:licenseIssureId => licenseIssureId찾기
 * @api 2. [get] /license-issuers/:licenseIssureId => licenseIssureId에 해당하는 자격증 정보
 * @api 2. [get] /license-issuers/:licenseIssureId/coach => licenseIssureId에 해당하는 자격증을 가진 전문가 리스트
 * @component [ProfileViewer, TabListViewer]
 */
export function LicensePage() {
  const { licenseUrl } = useParams();
  const navigate = useNavigate();

  /** 잘못 유입된 경우 */
  if (!licenseUrl) {
    alert(noneRegisterMessage);
    navigate(noneRegisterRoute);
    return <div>검색하신 자격증은 등록되지 않은 자격증입니다</div>;
  }

  /** page 데이터 요청 부분 */
  const { data: licenseIssureId } = useGetLicenseIssuerId({ licenseUrl });
  const { data: licenseIntroduces } = useGetLicenseIntroduce({
    licenseIssureId: licenseIssureId ?? ''
  });

  if (!licenseIntroduces) {
    return (
      <LoadingWrapper>
        <LoadingSpinner />
      </LoadingWrapper>
    );
  }
  return (
    <>
      <MetaHelmet
        title={licenseIntroduces.name}
        description={licenseIntroduces.introductions.소개글}
        url={'https://fibud.kr/license/' + licenseUrl}
        img={
          licenseIntroduces.images?.[0]?.uploadedLink ?? './ms-icon-70x70.png'
        }
        keywords={'Fibud 협회, ' + licenseIntroduces?.name + ', 협회'}
      />
      <PrevHeaderBarWithTitleTemplate title={licenseIntroduces.name}>
        {licenseIntroduces.images.length > 0 && (
          <ProfileViewer
            imgSrcList={licenseIntroduces.images.map(
              image => image.uploadedLink
            )}
            alt="자격증 소개 이미지"
          ></ProfileViewer>
        )}
        <TabListViewer initTab={0}>
          <TabListViewer.List tabs={['소개', '보유 전문가']} />
          <TabListViewer.Viewer
            tabs={[
              <LicenseIntroduce licenseIntroduce={licenseIntroduces} />,
              <LicenseCoachList />
            ]}
          />
        </TabListViewer>
      </PrevHeaderBarWithTitleTemplate>
    </>
  );
}

interface LicenseIntroduceProps {
  licenseIntroduce: ILicenseIntroduce;
}

function LicenseIntroduce({ licenseIntroduce }: LicenseIntroduceProps) {
  const introductions = licenseIntroduce?.introductions;
  const introduceContents = Object.entries(introductions).map(
    ([title, body]) => ({ title, body })
  );
  return (
    <TabIntroduce
      introduceContents={introduceContents}
      admin={{
        name: licenseIntroduce.adminName,
        accountId: licenseIntroduce.accountId
      }}
    />
  );
}

function LicenseCoachList() {
  const LicenseCoachListScrollKey = 'LicenseCoachListScrollKey';
  const { setScrollRestoration } = useScrollRestoration(
    LicenseCoachListScrollKey
  );

  const { licenseUrl } = useParams();

  const { data: licenseIssureId } = useGetLicenseIssuerId({
    licenseUrl: licenseUrl || ''
  });

  const { position } = useGeolocation();
  const { lat, lng } = position;

  const {
    data: coachList,
    hasNextPage,
    fetchNextPage
  } = useGetLicenseCoachList({
    licenseIssureId: licenseIssureId ?? '',
    lat,
    lng,
    offset: 10
  });

  const { setTarget } = useIntersectionObserver({
    hasNextPage: hasNextPage,
    fetchNextPage: fetchNextPage
  });

  if (!coachList) {
    return (
      <div
        style={{
          width: '100%',
          paddingTop: '30px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <div style={{ padding: '28px 20px 0' }}>
        <LocationItem />
      </div>
      <CoacheList
        coachesList={coachList}
        setScrollRestoration={setScrollRestoration}
        setTarget={setTarget}
      />
    </div>
  );
}
