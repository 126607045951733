import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3rem;
  gap: 1.6rem;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ImgBox = styled.div`
  width: 7.8rem;
  height: 9rem;
  > img {
    border-radius: 0.8rem;
  }
`;
const ExpertInfoBox = styled.div`
  display: flex;
  flex-direction: column;
`;
const ExpertCard = styled.div`
  display: flex;
  padding: 1.6rem;
  width: 100%;
  border-radius: 0.8rem;
  background-color: var(--color-gray-scale);
  gap: 1.6rem;
`;

export { Container, TitleBox, ExpertCard, ImgBox, ExpertInfoBox };
