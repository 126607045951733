import { Text } from '#atoms';
import { IGetClinicalData } from '#types/clinicalData';
import { useNavigate } from 'react-router';
import { ImageOrVideoRenderer } from '../ClinicalDataItem';
import {
  DataContainer,
  ImageContainer,
  PreviewContainer,
  PreviewTitleContainer
} from './styles';

interface Props {
  data?: IGetClinicalData[];
}

export const ClinicalDataList = ({ data }: Props) => {
  const navigate = useNavigate();

  return (
    <DataContainer>
      <Text
        textColor="white"
        style={{
          fontSize: '1.8rem',
          fontWeight: 600,
          textAlign: 'left'
        }}
      >
        최근 작성된 포트폴리오
      </Text>
      <div>
        {data?.map(data => (
          <PreviewContainer
            key={data.id}
            onClick={() =>
              navigate(`/expert/${encodeURIComponent(data.myUrl)}`)
            }
          >
            <PreviewTitleContainer>
              <Text textStyle="bb">{data.lectureName}</Text>
              {/* lectureName 최대 1줄 */}
              <Text textStyle="c2" textColor="gray2">
                {data.coachName} 전문가
              </Text>
            </PreviewTitleContainer>
            <ImageContainer className="preview">
              <div>
                <ImageOrVideoRenderer url={data.beforeImages[0]?.resizedLink} />
                <p className="before">전</p>
                <ImageOrVideoRenderer url={data.afterImages[0]?.resizedLink} />
                <p className="after">후</p>
              </div>
            </ImageContainer>
            {/* body 최대 3줄 */}
            <Text textStyle="c1" textColor="gray2">
              {data.body}
            </Text>
          </PreviewContainer>
        ))}
      </div>
    </DataContainer>
  );
};
