import { Container } from './styles';
import { FindExpertCard } from 'src/components/molecules/FindExpertCard';
import { ReservationCard } from 'src/components/molecules/ReservationCard';
export const MainConsumerCard = () => {
  return (
    <Container>
      <FindExpertCard />
      <ReservationCard />
    </Container>
  );
};
