import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface ChartProps {
  data: number[]; // 데이터 값 배열
  xLabels: (string | number)[]; // X축 라벨 배열 (날짜)
  circleColor?: string; // 선택된 점의 색상
  legend?: string; // 범례
  hasYLables?: boolean; // Y축 라벨 표시 여부
}

const MiniChart: React.FC<ChartProps> = ({
  data,
  xLabels,
  circleColor = '#6238dd',
  legend,
  hasYLables = false
}) => {
  // 기본적으로 가장 마지막 날짜의 인덱스를 선택
  const [selectedIndex, setSelectedIndex] = useState<number>(data.length - 1);

  const columnRef = useRef<HTMLDivElement>(null);
  const [columnWidth, setColumnWidth] = useState(0);

  const chartHeight = 176;

  // 데이터가 변경되면 선택된 인덱스 업데이트
  useEffect(() => {
    setSelectedIndex(data.length - 1);
  }, [data]);

  const maxValue = Math.max(...data);
  const minValue = Math.min(...data);
  const heightFactor = chartHeight / maxValue; // Y축의 높이에 맞게 데이터를 스케일링

  // Y축 라벨 계산
  const yLabels = [maxValue, minValue];

  // 각 점의 좌표를 계산하여 SVG로 선 연결
  const points = data.map((value, index) => {
    // 점 간 간격
    const distance = columnWidth;

    // x 위치 계산
    const x = index * distance + columnWidth / 2;
    const y = chartHeight - value * heightFactor; // y 위치 계산 (최대 높이가 140px)
    return { x, y };
  });

  useEffect(() => {
    if (columnRef.current) {
      setColumnWidth(columnRef.current.offsetWidth);
    }
  }, []);

  return (
    <div className="relative h-[200px] w-full flex gap-8">
      {/* Y축 라벨 */}
      {hasYLables && (
        <div className="w-fit mt-[-5px] flex flex-col justify-between items-end h-full pb-20 text-[#746f7e] text-xs font-normal font-['Pretendard Variable']">
          {yLabels.map((label, index) => (
            <div key={index} className="text-center">
              {label}
            </div>
          ))}
        </div>
      )}

      <div className="flex-1">
        {/* 차트 영역 */}
        <div
          style={{
            height: `${chartHeight}px`
          }}
          className="w-full flex relative"
        >
          {data.map((value, index) => {
            const normalizedHeight = value * heightFactor;
            const isSelected = index === selectedIndex;

            return (
              <div
                key={index}
                className="cursor-pointer flex-1 flex justify-center items-center relative z-10"
                onClick={() => setSelectedIndex(index)}
                ref={columnRef}
              >
                {isSelected && (
                  <div className="absolute left-1/2 transform -translate-x-1/2 bg-[#8E899F] w-1 h-full">
                    <div className="text-xs absolute top-[-25px] left=1/2 transform -translate-x-1/2">
                      {value}
                    </div>
                  </div>
                )}
                <div
                  style={{
                    top: `${chartHeight - normalizedHeight}px`,
                    backgroundColor: circleColor
                  }}
                  className={`absolute  w-8 h-8 rounded-full z-3`}
                >
                  {isSelected && <SelectedCircle />}
                </div>
              </div>
            );
          })}

          <svg className="absolute w-full top-[4px] z-1">
            {points.map((point, index) => {
              if (index === 0) return null;
              const previousPoint = points[index - 1];
              return (
                <StyledLine
                  index={index}
                  key={index}
                  x1={previousPoint.x}
                  y1={previousPoint.y}
                  x2={point.x}
                  y2={point.y}
                  stroke="var(--gray-600---border2, #2E2B36)"
                  strokeWidth="4"
                />
              );
            })}
          </svg>

          {legend && <Legend>{legend}</Legend>}
        </div>

        <div className="h-12" />

        {/* X축 라벨 */}
        <div className="mt-[-5px] flex w-full justify-between text-[#8e889f] text-xs font-normal font-['Pretendard Variable'] pl=16">
          {xLabels.map((label, index) => (
            <div key={index} className="text-center flex-1 flex justify-center">
              {label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const SelectedCircle = styled.div`
  width: 18px;
  height: 18px;
  position: absolute;
  left: 50%;
  bottom: 50%;
  border-radius: 50%;
  transform: translate(-50%, 50%);
  background: #7750e9;
  opacity: 24%;
`;

const Legend = styled.div`
  color: var(--Gray-Scale-800, #3f3d43);

  /* NEW/caption_02_regular */
  font-family: 'Pretendard Variable';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  position: absolute;
  right: 0;
  top: -35px;
`;

const StyledLine = styled.line<{ index: number }>`
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: draw 0.5s cubic-bezier(0.42, 0, 0.58, 1) forwards;
  animation-delay: ${props => props.index * 0.3}s;

  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

export default MiniChart;
