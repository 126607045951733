import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CoachCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem 2rem;
`;

export const Divider = styled.div`
  height: 8px;
  background: var(--bg-800, #1e1b24);
`;

export const Select = styled.select`
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: 0px solid transparent;
  background-color: transparent;
`;

export const Box = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--gray-800---bg2, #1f1e25);
  width: 100%;
`;
