import { Text } from '#atoms';
import { REVIEW_LIST } from '#const/review';
import { CoachReviews } from '#types/rates';
import { useNavigate } from 'react-router';
import { RatingStars } from 'src/components/molecules/RatingStar';
import {
  DataContainer,
  PreviewTitleContainer,
  ReviewCategory,
  ReviewContainer,
  ReviewLists,
  StarArea
} from './styles';

interface Props {
  data?: CoachReviews[];
}

export const ReviewList = ({ data }: Props) => {
  const navigate = useNavigate();

  return (
    <DataContainer>
      <Text
        textColor="white"
        style={{
          fontSize: '1.8rem',
          fontWeight: 600,
          textAlign: 'left'
        }}
      >
        최근 고객 후기
      </Text>
      <div>
        {data?.map(data => (
          <ReviewContainer
            key={data.id}
            onClick={() =>
              navigate(`/expert/${encodeURIComponent(data.myUrl)}`)
            }
          >
            <PreviewTitleContainer>
              {/* lectureName 최대 1줄 */}
              <Text textStyle="bb">{data.lectureName}</Text>
              <Text textStyle="c2" textColor="gray2">
                {data.coachName} 전문가
              </Text>
            </PreviewTitleContainer>
            <ReviewLists>
              {REVIEW_LIST.map(({ id, title }) => (
                <ReviewCategory key={id}>
                  <Text textStyle="bb">{title}</Text>
                  <StarArea>
                    <RatingStars mode="show" rate={data[id]} />
                  </StarArea>
                </ReviewCategory>
              ))}
            </ReviewLists>
            <Text textStyle="c1" textColor="gray2">
              {/* description 최대 3줄 */}
              {data.description}
            </Text>
          </ReviewContainer>
        ))}
      </div>
    </DataContainer>
  );
};
