import { useQueryString } from '#hooks/useQueryString';
import { useState } from 'react';
import MiniChart from '../MiniChart';
import { Box, Container, Select } from '../styles';
import { formatDateToMMDD } from '../utils';
import { useGetPainHistory } from './api';

export const PainChart = () => {
  const [registedLectureId] = useQueryString(['id']);

  const { data } = useGetPainHistory(registedLectureId);

  const [selectedPainPart, setSelectedPainPart] = useState<string>('');

  const onOptionChange = (painPart: string) => {
    setSelectedPainPart(painPart);
  };

  if (!data) return <div></div>;

  if (Object.keys(data.painHistory).length === 0) {
    return <Box>통증 부위가 없습니다.</Box>;
  }

  const painParts = Object.keys(data.painHistory).map(key => key);

  const painHistory: Record<
    string,
    {
      date: string;
      level: number;
    }[]
  > = {};
  painParts.forEach(painParts => {
    painHistory[painParts] = Object.entries(data.painHistory[painParts]).map(
      ([key, value]) => ({
        date: key,
        level: value
      })
    );
  });

  const xLabels = painHistory[selectedPainPart].map(painPart =>
    formatDateToMMDD(painPart.date)
  );

  return (
    <Box>
      <Container>
        <Select>
          <option disabled>운동 이름</option>
          {painParts.map(painPart => (
            <option onClick={() => onOptionChange(painPart)} key={painPart}>
              {painPart}
            </option>
          ))}
        </Select>
        <img
          className="absolute right-[12px]"
          src="/images/ic-arrow-down-16.svg"
          alt="arrow_down"
        />
      </Container>
      <div className="w-full h-0"></div>

      <MiniChart
        hasYLables
        data={
          painHistory[selectedPainPart]
            ? painHistory[selectedPainPart].map(painPart => painPart.level)
            : []
        }
        xLabels={xLabels}
      />
    </Box>
  );
};
