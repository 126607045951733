import { axios } from '#api/axios';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

interface IPostRecordsFeedback {
  // 운동 강도. 1: 너무 쉬움, 2: 쉬움, 3: 보통, 4: 어려움, 5: 너무 어려움
  exerciseIntensity: number;
  userComment?: string;
}

const postRecordsFeedback = async ({
  registeredLectureId,
  params
}: {
  registeredLectureId: string;
  params: IPostRecordsFeedback;
}) => {
  const res = await axios({
    url: `/records/${registeredLectureId}/feedback`,
    method: 'POST',
    data: params
  });

  return res.data;
};

export const usePostRecordsFeedback = (
  options?: UseMutationOptions<
    unknown, // 성공 시 반환되는 데이터 타입
    AxiosError, // 에러 타입
    { registeredLectureId: string; params: IPostRecordsFeedback }, // postRecordsFeedback 함수의 매개변수 타입
    unknown // mutation의 context 타입 (optional)
  >
) => {
  return useMutation(postRecordsFeedback, options);
};
