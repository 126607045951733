import styled from 'styled-components';

const Tags = styled.div`
  display: flex;
  gap: 0.6rem;
  margin-top: 0.8rem;
`;
const Tag = styled.div`
  padding: 0.4rem;
  color: var(--color-gray-text);
  font-size: 1.2rem;
  border-radius: 0.4rem;
  background-color: var(--color-gray);
  line-height: 100%;
`;

export { Tag, Tags };
