import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 3rem 2rem;

  & > header {
    padding: 3.2rem 0;
  }
`;

export const CategorySelectorContainer = styled.div`
  width: 100%;
  padding: 0.5rem 0.8rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-gray-scale);
  border-radius: 3.2rem;
  margin-top: 3rem;
  margin-bottom: 1.6rem;
`;

export const Category = styled.div<{ isSelected: boolean }>`
  width: 50%;
  text-align: center;
  padding: 1rem 1.5rem;
  border-radius: 3.2rem;
  cursor: pointer;
  color: ${props =>
    props.isSelected ? 'var(--color-white)' : 'var(--color-gray-text)'};
  background-color: ${props =>
    props.isSelected ? 'var(--color-point)' : 'var(--color-gray-scale)'};
`;
