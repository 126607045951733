import { Text } from '#atoms';
import LocationPin from '#assets/img/location_icon.png';
import { Container } from './styles';
import { useNavigate } from 'react-router';
export const FindExpertCard = () => {
  const navigate = useNavigate();
  return (
    <Container onClick={() => navigate('/expert?tab=전체')}>
      <Text textStyle="bb">내 주변 전문가</Text>
      <Text
        textColor="gray2"
        style={{ lineHeight: '3.2rem', fontSize: '1.4rem' }}
      >
        위치 기반 전문가 찾기
      </Text>
      <img src={LocationPin} width={82} height={82} />
    </Container>
  );
};
