import styled from 'styled-components';

export const CoachSelectorWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: center;
`;

export const GridArea = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  justify-items: center;
`;

export const CoachFilterWrap = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.75rem;
  // overflow-x: auto;
`;

export const FilterItem = styled.li`
  flex-shrink: 1;
  width: 100%;
  box-sizing: border-box;
  height: 2.8rem;
  padding: 0.25rem 1.25rem;
  border-radius: 3.2rem;
  border: 0.1rem solid var(--color-gray-second);
  color: var(--color-gray-text-second);
  cursor: pointer;
  &.select {
    border: 1px solid transparent;
    background-color: var(--color-point);
    color: var(--color-white);
  }
  &:nth-child(2) {
    flex-shrink: 0;
    width: 9.5rem; // 원하는 크기로 조정
  }

  // 4번 아이템 스타일링
  &:nth-child(3) {
    flex-shrink: 0;
    width: 9.5rem; // 원하는 크기로 조정
  }
`;

export const CoachInfoItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ImageArea = styled.div`
  position: relative;
  width: 100%;
  height: 13rem;
  border-radius: 1rem 1rem 0 0;

  &.alt {
    background-color: var(--color-gray-second);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem 1rem 0 0;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const InfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
  padding: 0.8rem 1rem;
  background-color: var(--color-black-alpha75);
  border-radius: 0 0 1rem 1rem;
  flex: 1;
  justify-content: space-around;

  .short-intro {
    padding-bottom: 0.3rem;
  }
`;

export const NameArea = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
`;

export const PriceArea = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  .bold {
    font-weight: 700;
  }
`;

export const GradeArea = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.4rem;
  color: var(--color-gray-text-second);

  svg {
    margin-right: 0.25rem;
  }

  & > p:not(:last-of-type)::after {
    content: '|';
    padding: 0 0.2rem;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 36px 0;
`;

export const ImgBox = styled.div`
  width: 10.6rem;
  height: 12.2rem;
  > img {
    width: 10.6rem;
    height: 12.2rem;
    border-radius: 0.8rem;
    object-fit: cover;
  }
`;

export const ExpertCard = styled.div`
  width: 100%;
  display: flex;
  gap: 1.6rem;
  cursor: pointer;
`;

export const ExpertDistance = styled.span`
  line-height: 100%;
  color: var(--color-white);
`;

export const ExpertPrice = styled.span`
  line-height: 100%;
  font-size: 1.8rem;
  color: var(--color-white);
  margin-left: 0.8rem;
`;

export const ExpertCardWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;
