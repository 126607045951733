import { useGetLecturesIng } from '#api/lectures';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { IRegisteredLecture } from '#types/registeredLectures';
import { convertRegisteredDateToScheduleDate } from '#utils/dateFormatter';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  ButtonContainer,
  Container,
  LoadingContainer,
  ScheduleList,
  TextContainer
} from './styles';

export function MyPageScheduleDetail() {
  const today = dayjs();
  const { lectureId } = useParams();
  const [searchParams] = useSearchParams();
  const coachName = searchParams.get('coach-name');
  const { data, isLoading } = useGetLecturesIng(lectureId ?? '');
  const registeredLectures = data?.registeredLectures;
  const navigate = useNavigate();
  const isCancellationDisabled = (registeredLecture: IRegisteredLecture) => {
    const lectureDate = dayjs(registeredLecture.taughtAt);
    return (
      registeredLecture.status !== 'NORMAL' ||
      today.isAfter(lectureDate, 'day') ||
      (today.isSame(lectureDate, 'day') && today.isAfter(lectureDate, 'hour'))
    );
  };
  if (isLoading) {
    return <LoadingContainer>loading...</LoadingContainer>;
  }

  return (
    <>
      {registeredLectures && (
        <>
          <PrevHeaderBarWithTitleTemplate
            prevRoute={PATH.MYPAGE_SCHEDULES}
            title="수업 일정 관리"
            defaultBackground
          >
            <Container>
              <TextContainer>
                <Text textStyle="t1">{data?.lecture.title}</Text>
              </TextContainer>
              <ScheduleList>
                {registeredLectures.length === 0 ? (
                  <TextContainer>
                    <Text>예약된 수업이 없습니다.</Text>
                  </TextContainer>
                ) : (
                  registeredLectures?.map((registeredLecture, idx) => (
                    <li key={idx}>
                      <Text textStyle="t3">{registeredLecture.round}회차</Text>
                      <div>
                        <Text textStyle="c1" textColor="gray2">
                          {registeredLecture.gym?.name}
                        </Text>
                        <Text textStyle="c1" textColor="gray2">
                          {convertRegisteredDateToScheduleDate(
                            registeredLecture.taughtAt,
                            true
                          )}
                        </Text>
                      </div>
                      {data?.allowSelfScheduling && (
                        <Button
                          disabled={isCancellationDisabled(registeredLecture)}
                          onClick={() =>
                            navigate(`cancellation?id=${registeredLecture.id}`)
                          }
                        >
                          예약 취소
                        </Button>
                      )}
                    </li>
                  ))
                )}
              </ScheduleList>
            </Container>
          </PrevHeaderBarWithTitleTemplate>

          {data?.allowSelfScheduling && !data.lecture.isGroup && (
            <ButtonContainer>
              <Button
                onClick={() => navigate('registration')}
                disabled={
                  data.calculatedRegisteredLecture
                    .remainingRegisteredLectureCount <= 0
                }
              >
                수업 예약하기
              </Button>
            </ButtonContainer>
          )}
        </>
      )}
    </>
  );
}
