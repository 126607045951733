import styled from 'styled-components';

const Container = styled.div`
  width: 50%;
  height: 100%;
  gap: 1.6rem;
  display: flex;
  // align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

const Card = styled.div`
  padding: 2.1rem;
  width: 100%;
  height: 100%;
  background-color: var(--color-gray);
  border-radius: 0.6rem;
  display: flex;
  // align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const OnePointCard = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
  width: 50%;
  background-color: var(--color-gray);
  img {
    margin: auto;
  }
`;
export { Container, Card, OnePointCard };
