import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { RecordResponseDto } from './type';

interface getRecordsV2Params {
  registeredLectureId: string;
}

/**
 * 유저 및 코치 - registeredLectureId의 별점 & 운동기록 상세 조회
 */
export async function getRecordsV2({
  registeredLectureId
}: getRecordsV2Params) {
  const res = await request<RecordResponseDto>({
    url: `/records/${registeredLectureId}`,
    method: 'get'
  });

  return res.data;
}

export const useGetRecordsV2 = (
  registeredLectureId: string,
  options?: UseQueryOptions<
    RecordResponseDto,
    AxiosError,
    RecordResponseDto,
    string[]
  >
) => {
  return useQuery(
    queryKeyFactory.GET_RECORDS(registeredLectureId),
    () => getRecordsV2({ registeredLectureId }),
    {
      enabled: !!registeredLectureId,
      ...options
    }
  );
};
