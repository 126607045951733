import { useGetDiseaseKinds } from '#api/disease_kind';
import { useGetJobs } from '#api/jobs';
import { useGetPainPartKinds } from '#api/pain-part-kinds';
import { useGetAllTags } from '#api/tags';
import { Button, Text, Textarea } from '#atoms';
import { SLEEPING_HOUR_AVG_LIST } from '#const/confirmationForms/filter';
import { PATH } from '#const/path';
import { useApplications } from '#providers/Applications';
import { FibudContainerTemplate } from '#templates';
import { exerciseCareerFormatter } from '#utils/dateFormatter';
import sessionStorageManager from '#utils/sessionStorageManager';
import { sleepQualityToText } from '#utils/sleepQuality';
import { useCallback } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation, useNavigate } from 'react-router';
import { URLSEARCH_PARAMS } from 'src/shared/constant';
import { Container, TextContainer } from './styles';

export function ApplicationEtc() {
  const navigate = useNavigate();
  const { updateCurrentApplications, currentApplication, postApplicationForm } =
    useApplications();

  // search에서 confirm 가져오기
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const confirmationFormId = params.get(URLSEARCH_PARAMS.CONFIRMATION_FORM_ID);

  console.log(confirmationFormId);

  const { data: tags } = useGetAllTags();
  const { data: jobs } = useGetJobs();
  const { data: diseasesData } = useGetDiseaseKinds();
  const { data: painPartsData } = useGetPainPartKinds();
  const { removeSessionData, setConfirmationFormId } = sessionStorageManager();
  const selectedDiseases = [
    ...(currentApplication?.healthInfo?.diseases?.map(
      value =>
        diseasesData?.data.find(item => item.id === value.diseaseKindId)
          ?.name ?? ''
    ) ?? []),
    ...(currentApplication?.healthInfo?.diseaseEtc
      ? [currentApplication?.healthInfo?.diseaseEtc]
      : [])
  ];
  const selectedPainParts = [
    ...(currentApplication?.healthInfo?.painParts?.map(
      value =>
        painPartsData?.data.find(item => item.id === value.kindId)?.name ?? ''
    ) ?? []),
    ...(currentApplication?.healthInfo?.painPartEtc
      ? [currentApplication?.healthInfo?.painPartEtc]
      : [])
  ];

  const updateApplicationEtc = useCallback(
    (etc: string) => {
      updateCurrentApplications({ etc });
    },
    [currentApplication]
  );

  const onNextButtonClick = async () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'click_checkout_2',
        height: currentApplication?.healthInfo?.height,
        weight: currentApplication?.healthInfo?.weight,
        experience: exerciseCareerFormatter(currentApplication?.carrer),
        purpose: currentApplication?.purposes?.map(
          p => tags?.data.find(t => t.id === p)?.name
        ),
        sleep_quality: sleepQualityToText(
          currentApplication?.healthInfo?.qualityOfSleep ?? 0
        ),
        sleep_amount: SLEEPING_HOUR_AVG_LIST.find(
          e => e.id === (currentApplication?.healthInfo?.sleepingHourAvg ?? 0)
        )?.name,
        job: jobs?.data.find(j => j.id === currentApplication?.job)?.name,
        diseases: selectedDiseases,
        surgical_records: selectedPainParts,
        question: currentApplication?.etc
      }
    };
    TagManager.dataLayer(tagManagerArgs);
    postApplicationForm();

    navigate(`${PATH.SUCCESS}`);
    removeSessionData('confirmationFormId');
  };

  return (
    <FibudContainerTemplate type="paddingWithBottomButton">
      <FibudContainerTemplate type="bottomButtonContainer">
        <Container>
          <TextContainer>
            <Text textStyle="t1">
              추가설명 및 문의사항을
              <br />
              입력해주세요
            </Text>
          </TextContainer>
          <Textarea
            value={currentApplication?.etc ?? ''}
            onChange={updateApplicationEtc}
            height={19}
            limit={500}
            placeholder="내용을 적어주세요"
          ></Textarea>
        </Container>
      </FibudContainerTemplate>
      <FibudContainerTemplate type="bottomButton">
        <Button onClick={() => onNextButtonClick()}>다음</Button>
      </FibudContainerTemplate>
    </FibudContainerTemplate>
  );
}
