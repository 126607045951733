import { Text } from '#atoms';
import { Container } from './styles';
export const MainConsumerTitle = () => {
  return (
    <Container>
      <Text>한 차원 높은 수준의</Text>
      <span id="text-point">퍼스널 트레이닝</span>
      <span>을</span>
      <Text>경험해보세요</Text>
    </Container>
  );
};
