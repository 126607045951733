import { usePatchReviews } from '#api/reviews';
import { useQueryString } from '#hooks/useQueryString';
import { Modal } from '#molecules';
import { useToast } from '#providers/ToastProvider';
import { useState } from 'react';
import styled from 'styled-components';
import { useGetRecordsV2 } from '../../api';
import { StarRating } from './StarRating';
import { useRatingState } from './ratingState';

export const EditButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [registeredLectureId] = useQueryString(['id']);

  const { data } = useGetRecordsV2(registeredLectureId);

  const coachNice = data?.lectureRate?.coachNice;
  const timeNice = data?.lectureRate?.timeNice;
  const lectureQuality = data?.lectureRate?.lectureQuality;

  const { ratingState, setRatingState } = useRatingState();

  const { showToast } = useToast();

  const { mutate } = usePatchReviews({
    onSuccess: () => {
      setIsModalOpen(false);
      showToast({
        type: 'success',
        message: '평가가 수정되었습니다.'
      });
    },
    onError: () => {
      setIsModalOpen(false);
      showToast({
        type: 'fail',
        message: '평가 수정에 실패했습니다.'
      });
    }
  });

  const onEdit = () => {
    mutate({
      registeredLectureId,
      coachNice: ratingState.coachNice,
      timeNice: ratingState.timeNice,
      lectureQuality: ratingState.lectureQuality
    });
    setIsModalOpen(false);
  };

  const disabled = false;

  const text = disabled ? '수정완료' : '수정';

  return (
    <>
      <Button
        disabled={disabled}
        isEdit={false}
        onClick={() => setIsModalOpen(true)}
      >
        {text}
      </Button>

      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <div className="p-4 w-full">
            <div className="text-[#bcb8c3] text-xl font-semibold font-['Pretendard Variable'] leading-normal tracking-tight">
              평점 수정
            </div>

            <div className="w-full h-24"></div>

            <StarRating />

            <div className="w-full h-32"></div>

            <div className="flex gap-12 w-full">
              <div
                onClick={() => {
                  setIsModalOpen(false);
                }}
                className="flex-1 h-[45px] px-10 py-3 bg-[#2e2a3a] rounded-lg justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-[#bcb8c3] text-base font-medium font-['Pretendard Variable'] leading-[21px]">
                  취소
                </div>
              </div>
              <div
                onClick={onEdit}
                className="flex-1 h-[45px] px-10 py-3 bg-[#7750e9] rounded-lg justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-[#ebe9f4] text-base font-medium font-['Pretendard Variable'] leading-[21px]">
                  확인
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const Button = styled.button<{ isEdit: boolean }>`
  color: ${({ isEdit }) =>
    isEdit ? '#595463' : 'var(--Brand-300, var(--C79EF0, #9170f2));'};

  /* NEW/body_02_medium */
  font-family: 'Pretendard Variable';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.32px;
`;
