import { Suspense } from 'react';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner';
import { Divider } from '../styles';
import { ChartExerciseList } from './ChartExerciseList';
import { ExerciseChart } from './ExerciseChart';
import { TrainerFeedback } from './TrainerFeedback';

export const ExerciseRecord = () => {
  return (
    <>
      <div className="px-20 pb-32">
        <div className="text-[#d7d2de] text-[18px] font-medium font-['Pretendard Variable']">
          운동내역
        </div>
        <div className="w-full h-16"></div>
        <Suspense
          fallback={
            <div className="flex justify-center items-center">
              <LoadingSpinner />
            </div>
          }
        >
          <ChartExerciseList />
        </Suspense>
      </div>
      <Divider />
      <div className="px-20 pb-32">
        <Suspense
          fallback={
            <div className="flex justify-center items-center">
              <LoadingSpinner />
            </div>
          }
        >
          <ExerciseChart />
        </Suspense>
      </div>
      <Divider />
      <div className="px-20 pb-60">
        <Suspense
          fallback={
            <div className="flex justify-center items-center">
              <LoadingSpinner />
            </div>
          }
        >
          <TrainerFeedback />
        </Suspense>
      </div>
    </>
  );
};
