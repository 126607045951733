interface StarsProps {
  index: number | undefined;
  onClick: (index: number) => void;
}

export const Stars = ({ index, onClick }: StarsProps) => {
  const safeIndex = index || 0;

  const handleClick = (clickedIndex: number) => {
    // 이미 선택된 별을 다시 클릭하면 선택을 해제 (index를 0으로 설정)
    if (safeIndex === clickedIndex) {
      onClick(0);
    } else {
      onClick(clickedIndex);
    }
  };

  return (
    <div className="flex gap-8">
      {Array(5)
        .fill(undefined)
        .map((_, i) => (
          <div key={i} onClick={() => handleClick(i + 1)}>
            <Star isSelected={i < safeIndex} />
          </div>
        ))}
    </div>
  );
};

const Star = ({ isSelected }: { isSelected: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5.825 22L7.45 14.975L2 10.25L9.2 9.625L12 3L14.8 9.625L22 10.25L16.55 14.975L18.175 22L12 18.275L5.825 22Z"
        fill={isSelected ? '#7750E9' : '#373245'}
      />
    </svg>
  );
};
