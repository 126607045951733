import styled from 'styled-components';

const Container = styled.div`
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-family: 'Pretendard Variable';
  padding-top: 2rem;
  #text-point {
    color: var(--color-point);
  }
`;

export { Container };
