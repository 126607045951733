import { useQueryString } from '#hooks/useQueryString';
import styled from 'styled-components';
import { useGetRecordsV2 } from '../api';

export const TrainerFeedback = () => {
  const [registeredLectureId] = useQueryString(['id']);

  const { data } = useGetRecordsV2(registeredLectureId, {
    suspense: true
  });

  if (!data) return <div></div>;

  return (
    <div className="w-full pt-32">
      <div className="text-[#d7d2de] text-[18px] font-medium font-['Pretendard Variable']">
        트레이너 피드백
      </div>
      <div className="w-full h-16"></div>
      <Box>{data.registeredLectureRecord.comment || ''}</Box>
    </div>
  );
};

const Box = styled.div`
  display: flex;
  width: 100%;
  min-height: 240px;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  background: var(--BG-bg-700, #262231);

  color: var(--Gray-Scale-400, #bcb8c3);

  /* NEW/body_02_regular */
  font-family: 'Pretendard Variable';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;
